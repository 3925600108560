<template>
  <div class="page1">
    <div class="info" v-if="closeShow">
      <el-row>
        <el-col :span="24">
          <div class="info_content">
            <el-col :span="23">
              <span
                >总收入报表，统计支付日期内的各业务类型的收入和退款情况；适用于运营人员分析各业务营收情况。</span
              >
            </el-col>
            <el-col :span="1">
              <img src="./img/close.png" @click="closeShow = false" alt="" />
            </el-col>
          </div>
        </el-col>
      </el-row>
    </div>
    <!--主体内容-->
    <div class="content bgFFF">
      <!--搜索条件区域-->
      <div class="searchWrapper">
        <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
        <el-form
          :inline="true"
          status-icon
          label-position="right"
          label-width="75px"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
        >
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.date')" label-width="60px">
                <a-date-picker
                  ref="datePicker"
                  label="right"
                  :YearShowHidden="selectkeys[3]"
                  :selectkeys="selectkeys"
                  :previousDate="previousDate"
                  :nextDate="nextDate"
                ></a-date-picker>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.region')" prop="areaId">
            <a-cascader ref="cascader" @change="parkClear"></a-cascader>
          </el-form-item> -->
              <el-form-item :label="$t('searchModule.Merchant_affiliation')">
                <a-operation-select ref="operationSelect" @change="parkClear"></a-operation-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >查询
              </el-button>
            </div>
          </div>
          <div class="col_box_boder" v-if="$route.meta.authority.button.export"></div>
          <div class="col_box h44">
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <exportFile
                :exportData="exportData"
                v-if="$route.meta.authority.button.export"
              ></exportFile>
            </div>
          </div>
        </el-form>
      </div>

      <div class="statusContent">
        <div class="status_info">
          <div class="status_img">
            <img src="./img/count1.png" alt="" />
          </div>
          <div class="status_word">
            <p>
              <span>收入合计</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="停车收入实付+停车卡订单实收+预约订单实收+错锋订单实收"
                placement="top-start"
              >
                <img src="./img/tip.png" alt="" />
              </el-tooltip>
            </p>
            <p>
              {{ totalObj.totalActualPayMoney | moneyHandling }}
              <span>元</span>
            </p>
          </div>
        </div>
        <div class="status_info">
          <div class="status_img">
            <img src="./img/count2.png" alt="" />
          </div>
          <div class="status_word">
            <p>
              <span>停车收入合计</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="按停车支付时间在统计日期内实付金额之和"
                placement="top-start"
              >
                <img src="./img/tip.png" alt="" />
              </el-tooltip>
            </p>
            <p>
              {{ totalObj.totalTemporaryParkActualPayMoney | moneyHandling }}
              <span>元</span>
            </p>
          </div>
        </div>
        <div class="status_info">
          <div class="status_img">
            <img src="./img/count3.png" alt="" />
          </div>
          <div class="status_word">
            <p>
              <span>停车卡收入</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="按支付时间在统计日期的停车卡实付金额之和"
                placement="top-start"
              >
                <img src="./img/tip.png" alt="" />
              </el-tooltip>
            </p>
            <p>
              {{ totalObj.totalParkCardPayMoney | moneyHandling }}
              <span>元</span>
            </p>
          </div>
        </div>
        <div class="status_info">
          <div class="status_img">
            <img src="./img/count4.png" alt="" />
          </div>
          <div class="status_word">
            <p>
              <span>预约停车收入</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="按支付时间在统计日期的停车预约实付金额之和"
                placement="top-start"
              >
                <img src="./img/tip.png" alt="" />
              </el-tooltip>
            </p>
            <p>
              {{ totalObj.totalAppointmentPayMoney | moneyHandling }}
              <span>元</span>
            </p>
          </div>
        </div>
        <div class="status_info">
          <div class="status_img">
            <img src="./img/count5.png" alt="" />
          </div>
          <div class="status_word">
            <p>
              <span>错峰停车收入</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="按支付时间在统计日期的错锋订单实付金额之和"
                placement="top-start"
              >
                <img src="./img/tip.png" alt="" />
              </el-tooltip>
            </p>
            <p>
              {{ totalObj.totalStaggeredPayMoney | moneyHandling }}
              <span>元</span>
            </p>
          </div>
        </div>
        <div class="status_info">
          <div class="status_img">
            <img src="./img/count6.png" alt="" />
          </div>
          <div class="status_word">
            <p>
              <span>退款合计</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="退实付+停车卡退款+预约退款"
                placement="top-start"
              >
                <img src="./img/tip.png" alt="" />
              </el-tooltip>
            </p>
            <p>
              {{ totalObj.totalRefundMoney | moneyHandling }}
              <span>元</span>
            </p>
          </div>
        </div>
      </div>

      <!--列表区域-->
      <div class="tableWrapper paddingB20">
        <!-- 筛选按钮 -->
        <div class="switch">
          <screen
            :checkBoxGroup="colData"
            :checkedColumns="checkedColumns"
            :reportType="5"
            @selectChange="selectChange"
          ></screen>
        </div>
        <el-table
          :key="reload"
          header-cell-class-name="headercellStyle"
          :cell-style="cellStyle"
          border
          v-loading="loading"
          :data="tableData"
          class="table-wrapper"
          style="width: 100%"
        >
          <el-table-column label="" min-width="150" class-name="headerCellQ cell-wrapper">
            <el-table-column
              v-if="colData[0].isTrue"
              prop="reportDate"
              align="center"
              :label="$t('searchModule.Statistical_date')"
              min-width="150"
              class-name="title-wrapper"
            ></el-table-column>
            <el-table-column
              v-if="colData[1].isTrue"
              prop="operationName"
              align="center"
              :label="$t('searchModule.Merchant_affiliation')"
              min-width="150"
              class-name="title-wrapper"
            ></el-table-column>
          </el-table-column>
          <el-table-column
            align="center"
            min-width="150"
            class-name="cell-wrapper white-wrapper"
            v-if="colData[2].isTrue || colData[3].isTrue"
          >
            <template slot="header">
              <span>停车收入</span>
              <i class="iconfont icontishi"></i>
            </template>
            <el-table-column
              v-if="colData[2].isTrue"
              prop="actualPayMoney"
              align="center"
              label="实付"
              min-width="150"
              class-name="title-wrapper"
            >
              <div slot-scope="scope">
                <span>{{ scope.row.actualPayMoney | moneyHandling }}</span>
              </div>
            </el-table-column>
            <el-table-column
              v-if="colData[3].isTrue"
              prop="refundMoney"
              align="center"
              label="退实付"
              min-width="150"
              class-name="title-wrapper"
            >
              <div slot-scope="scope">
                <span>{{ scope.row.refundMoney | moneyHandling }}</span>
              </div></el-table-column
            >
          </el-table-column>
          <el-table-column
            align="center"
            min-width="150"
            class-name="headerCellW cell-wrapper"
            v-if="colData[4].isTrue || colData[5].isTrue"
          >
            <template slot="header">
              <span>停车卡收入</span>
            </template>
            <el-table-column
              v-if="colData[4].isTrue"
              prop="parkCardPayMoney"
              align="center"
              label="停车卡订单实收"
              min-width="150"
              class-name="title-wrapper"
            >
              <div slot-scope="scope">
                <span>{{ scope.row.parkCardPayMoney | moneyHandling }}</span>
              </div></el-table-column
            >
            <el-table-column
              v-if="colData[5].isTrue"
              prop="parkCardRefundMoney"
              align="center"
              label="停车卡退款"
              min-width="150"
              class-name="title-wrapper"
            >
              <div slot-scope="scope">
                <span>{{ scope.row.parkCardRefundMoney | moneyHandling }}</span>
              </div></el-table-column
            >
          </el-table-column>

          <el-table-column
            align="center"
            min-width="150"
            class-name="cell-wrapper white-wrapper"
            v-if="colData[6].isTrue || colData[7].isTrue"
          >
            <template slot="header">
              <span>预约停车收入</span>
            </template>

            <el-table-column
              v-if="colData[6].isTrue"
              prop="appointmentPayMoney"
              align="center"
              label="预约订单实收"
              min-width="150"
              class-name="title-wrapper"
            >
              <div slot-scope="scope">
                <span>{{ scope.row.appointmentPayMoney | moneyHandling }}</span>
              </div></el-table-column
            >
            <el-table-column
              v-if="colData[7].isTrue"
              prop="appointmentRefundMoney"
              align="center"
              label="预约退款"
              min-width="150"
              class-name="title-wrapper"
            >
              <div slot-scope="scope">
                <span>{{ scope.row.appointmentRefundMoney | moneyHandling }}</span>
              </div></el-table-column
            >
          </el-table-column>
          <el-table-column
            align="center"
            min-width="150"
            class-name="headerCellW cell-wrapper"
            v-if="colData[8].isTrue"
          >
            <template slot="header">
              <span>错峰停车收入</span>
            </template>

            <el-table-column
              v-if="colData[8].isTrue"
              prop="staggeredPayMoney"
              align="center"
              label="错峰订单实收 "
              min-width="150"
              class-name="title-wrapper"
            >
              <div slot-scope="scope">
                <span>{{ scope.row.staggeredPayMoney | moneyHandling }}</span>
              </div></el-table-column
            >
          </el-table-column>
          <el-table-column align="center" min-width="150" class-name="headerCellE cell-wrapper">
            <el-table-column
              v-if="colData[9].isTrue"
              prop="dailyTotalPayMoney"
              align="center"
              label="收入合计"
              min-width="150"
              class-name="title-wrapper"
              ><div slot-scope="scope">
                <span>{{ scope.row.dailyTotalPayMoney | moneyHandling }}</span>
              </div></el-table-column
            >
            <el-table-column
              v-if="colData[10].isTrue"
              prop="dailyTotalRefundMoney"
              align="center"
              label="退款合计"
              min-width="150"
              class-name="title-wrapper"
            >
              <div slot-scope="scope">
                <span>{{ scope.row.dailyTotalRefundMoney | moneyHandling }}</span>
              </div></el-table-column
            >
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper bgFFF">
          <el-pagination
            background
            v-if="total != 0"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { exportExcelNew } from "@/common/js/public.js";
import screen from "@/components/screen/index";
// import myComponent from "@/components/autocomplete/myautoComponent";
import exportFile from "@/components/exportFile/exportFile.vue";

export default {
  name: "parkIncomeForm",
  components: { screen, exportFile },
  data() {
    return {
      exportData: {},
      closeShow: true,
      screeningNum: 0,
      options: [],
      value1: [],
      selectkeys: ["date", "month", "week", "year"],
      reload: 0,
      totalObj: {},
      previousDate: new Date(),
      nextDate: new Date(),
      // colData中列出表格中的每一列，默认都展示
      colData: [
        { label: "统计日期", isTrue: false },
        { label: this.$t("list.Merchant_affiliation"), isTrue: false },
        { label: "实付", isTrue: false },
        { label: "退实付", isTrue: false },
        { label: "停车卡订单实收", isTrue: false },
        { label: "停车卡退款", isTrue: false },
        { label: "预约订单实收", isTrue: false },
        { label: "预约退款", isTrue: false },
        { label: "错峰订单实收", isTrue: false },
        { label: "收入合计", isTrue: false },
        { label: "退款合计", isTrue: false },
      ],
      // 当前选中的多选框，代表当前展示的列
      checkedColumns: [],
      pageNum: 1,
      pageSize: 15,
      total: 0,
      tableData: [],
      loading: false,
      type1: "c3",
      type2: "c4",
      formInline: {
        startDate: "",
        endDate: "",
      },
    };
  },
  watch: {},
  methods: {
    selectChange(selectList) {
      this.colData.filter((i, index) => {
        i.isTrue = false;
      });
      selectList.forEach((i) => {
        this.colData[i].isTrue = true;
      });
      this.searchData();
    },
    getReportFieldHide() {
      this.$axios
        .get("/acb/2.0/reportFieldHide/get", {
          data: {
            reportType: 5,
          },
        })
        .then((res) => {
          if (res.value.length == 1 && res.value[0] == "") {
            this.checkedColumns = [];
          } else {
            this.checkedColumns = res.value.map(Number);
          }
          this.checkedColumns.forEach((i) => {
            this.colData[i].isTrue = true
          });
        });
    },
    // table 单元格样式动态设置
    cellStyle(row) {
      return {
        "border-right": 0,
      };
    },
    moneyFormatter(row, column, cellValue, index) {
      if (cellValue) {
        return Number(cellValue / 100).toFixed(2);
      } else {
        return "0.00";
      }
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    searchData() {
      let param = {
        page: this.pageNum,
        pageSize: this.pageSize,
        // areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
        startDate: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
        endDate: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
        operationId: this.$refs.operationSelect ? this.$refs.operationSelect.getOperationId() : "",
        reportType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
      };
      this.exportExcelReport();
      this.$axios
        .get("/acb/2.0/revenueReport/list", {
          data: param,
        })
        .then((res) => {
          if (res.state == 0) {
            this.getTotalList();
            this.total = parseInt(res.value.total) || 0;
            this.tableData = res.value.list || [];
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    getTotalList() {
      let param = {
        page: this.pageNum,
        pageSize: this.pageSize,
        startDate: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
        endDate: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
        operationId: this.$refs.operationSelect ? this.$refs.operationSelect.getOperationId() : "",
        reportType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
      };
      this.$axios
        .get("/acb/2.0/revenueReport/queryTotal", {
          data: param,
        })
        .then((res) => {
          if (res.state == 0) {
            console.log("res", res);
            this.totalObj = res.value || {};
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 停车场收入报表 导出
    exportExcelReport() {
      let opt = {
        fileName: "营收总报表",
        page: this.pageNum,
        pageSize: this.pageSize,
        reportType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
        startDate: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
        endDate: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
        operationId: this.$refs.operationSelect ? this.$refs.operationSelect.getOperationId() : "",
        name: "营收总报表",
      };
      // 导出数据
      this.exportData = {
        url: "/acb/2.0/revenueReport/exportlist",
        methods: "post",
        data: opt,
      };
    },
    // 时间判断
    showLog() {
      if (this.formInline.startDate && this.formInline.endDate) {
        let time = new Date(this.formInline.endDate) - new Date(this.formInline.startDate);
        time = time / (24 * 60 * 60 * 1000);
        if (this.formInline.dateType === "0") {
          if (time >= 31) {
            this.$alert("查询时间不能大于31天");
            return false;
          } else {
            return true;
          }
        } else {
          if (time > 365) {
            this.$alert("查询时间不能大于12个月");
            return false;
          } else {
            return true;
          }
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
  },
  mounted() {
    this.searchData();
    // this.getTotalList();
    this.getReportFieldHide();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.pagerWrapper {
  margin-top: 0;

  .el-pagination {
    padding: 20px 0;
    text-align: right;
  }
}

.breadcrumb {
  height: 35px;
}

.content {

  .but {
    float: right;
    padding-bottom: 20px;
  }

  .list {
    margin-top: 20px;

    .el-col {
      padding-bottom: 18px;
      height: 320px;

      .grid-content {
        padding-top: 15px;
        height: 100%;
      }

      h2 {
        font-size: 18px;
        text-align: center;
      }
    }
  }
}

.info {
  position: relative;
  left: -20px;
  width: calc(100% + 20px);
  height: 40px;
  padding-left: 20px;
  line-height: 40px;
  background: rgba(254, 242, 213, 0.47);

  .info_content {
    background: rgba(254, 242, 213, 0.47);
    color: rgba(255, 151, 74, 1);
  }

  img {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    cursor: pointer;
  }
}

.searchWrapper >>> .el-button {
  margin-right: 0 !important;
  margin-left: 10px !important;
}

.searchWrapper >>> .el-form-item__label {
  padding: 0 8px 0 0;
}

.tableWrapper {
  margin-top: 16px;
  position: relative;

  .switch {
    position: absolute;
    right: 0px;
    z-index: 2;
    top: 39px;
    cursor: pointer;
    img {
      height: 54px;
      width: 40px;
    }
  }
}

.tableWrapper >>> .el-table {
  width: 100% !important;
}

.tableWrapper >>> .headercellStyle {
  font-size: 14px;
  background: #F3F7FF !important;
  padding: 5px;
  border-right: 0;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);

  img {
    width: 14px;
    height: 14px;
    padding-left: 5px;
  }
}

.tableWrapper >>> .headerCellQ {
  background: #ffffff !important;
}

.tableWrapper >>> th.el-table__cell {
  border-right: 1px solid #CFDAEF;
}

.tableWrapper >>> .headerCellW {
  background: #ffffff !important;
}

.tableWrapper >>> .headerCellE {
  background: #ffffff !important;
}

.table-wrapper /deep/ .cell-wrapper {
  height: 30px;
  color: #526097;
}

.table-wrapper >>> tr >>> th >>> .cell {
  height: 30px !important;
}

.table-wrapper /deep/ .white-wrapper {
  background: #fff !important;
}

.table-wrapper /deep/ .title-wrapper {
  height: 55px;
  color: #000;
}

.tableWrapper >>> .el-table__body-wrapper::-webkit-scrollbar {
  width: 10px; // 横向滚动条
  height: 10px; // 纵向滚动条 必写
  background: transparent;
  border-radius: 10px;
}

// 滚动条的滑块样式
.tableWrapper >>> .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(217, 225, 238, 1);
  border-radius: 10px;
}

.statusContent {
  height: 110px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  background: #ffffff;

  .status_info {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .blueWord {
      color: #678BFF;
    }

    .status_img {
      width: 54px;
      height: 54px;
      padding: 0 4px 0 20px;

      img {
        width: 54px;
        height: 54px;
      }
    }

    .status_word {
      // line-height: 33px;
      height: 54px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      p {
        white-space: nowrap;
      }

      img {
        width: 14px;
        height: 14px;
        vertical-align: middle;
      }
    }

    .status_word span:first-child {
      color: #6C7293;
      font-size: 12px;
      padding-right: 4px;
    }

    .status_word p:last-child {
      color: #454458;
      font-size: 18px;
      font-weight: 500;
    }
  }

  // .status_info:last-child {
  // justify-content: flex-end;
  // margin-right: 30px;
  // }
  .switchType {
    p {
      font-size: 19px;
      color: rgba(122, 130, 138, 1);
    }

    img {
      width: 16px;
      height: 16px;
      vertical-align: middle;
      margin-bottom: 1px;
    }
  }
}
</style>
